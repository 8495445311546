const CommonConfig = {
    Regions: {
        'india-kerala': '00000000-0000-0000-0000-000000000001',
        'india-tamil-nadu': '00000000-0000-0000-0000-000000000002',
        'india-karnataka': '00000000-0000-0000-0000-000000000003',
        'india-maharastra': '00000000-0000-0000-0000-000000000004'
    },
    globalSellerId: '00000000-0000-0000-0000-000000000000'
}

const UserViewConstants = {
    Permissions: [
        "Admin",
        "Sales Staff",
        "Marketing Staff",
        "Billing Staff"
    ]
}

const StoreViewConstants = {
    StoreCategories: [
        "Books",
        "Furnitures",
        "Electronics",
        "Home Appliances",
    ],

}

const DeliveryTypes = [
    "PickUp",
    "Home-Delivery"
]
const DeliveryConstant = {
    PickUp: "PickUp",
    Delivery: "Home-Delivery"
}

const DeliveryModel = {
    typeSelect: {
        'PickUp': ['PickUp'],
        'Home-Delivery': ['PickUp', 'Home-Delivery'],

    }
}

enum ViewModelStatus {
    New = "NEW",
    Saved = "SAVED"
}

enum CataloguePublishStatus {
    publish = "submitted-to-publish",
    unPublish = "submitted-to-unpublish",
    draft = "draft",
    published = "published",
    unPublished="unpublished"
}

enum commodityDataTypes {
    text = "Text",
    number = "Number",
    file = "filecollection",
    date = "Date"
}
enum customerDataTypes {
    text = "Text",
    number = "Number",
}

enum FileUploadCommodity {
    Commodity = "commodity",
    Images = "images",
    SecureLevelCode = "Public"


}
enum CustomizeTemplateDataType {
    Color = "COLOR",
    Image = "IMAGE",
    Text = "TEXT"



}
enum ReportIssue {
    ReportEmail = "bis.buyconn.com",
    SecondaryReportEmail = "jaseem.rahman@buyconn.com"
}

enum bulkUploadCommodityFileConfig {
    uploadedFor = "bulk-commodity",
    collectionType = "csv",
    secureLevelCode = "secure",
}
enum mediaFileConfig {
    uploadedFor = "mediaPost",
    collectionType = "media",
    secureLevelCode = "public",
}

enum SellerBillingDocFileConfig {
    uploadedFor = "seller-doc",
    collectionType = "doc",
    secureLevelCode = "secure",
}

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

enum searchAttributes {
    commoditySearchMode = 'global'
}

enum signUpOTPAdditionalAttrib {
    issuedFor = "seller-account-user-sign-up",
    tagKey = "SellerAccount",
    purpose = "SellerAccountUserSignUpIssue"
}

enum forgetPasswordOTPAdditionalAttrib {
    issuedFor = "reset-user-credential",
    tagKey = "SellerAccount",
    purpose = "reset-credentials"
}
enum reportQueryFiterBy {
    seller = 'seller',
    stores = 'stores'
}
enum getReportAganistReportId {
    totalOrder = 'total_order',
    currentOrderCount = 'current_ordercount'
}
enum getToTalOrderLabel {
    categorizedByStatusNew = "categorizedByStatusNew",
    categorizedByStatusAccepted = "categorizedByStatusAccepted",
    categorizedByStatusIntransist = "categorizedByStatusIntransist",


}
enum setSalesChartLabel {
    New = "New",
    Accepted = "Accepted",
    Intransist = "Intransist"

}

enum reportStages {
    salesAnalaticsStage = "sales",
    productAnalaticsStage = "product",
    locationAnalaticsStage = "location"
}
enum dashboardStages {
    assetsAnalytics = "Assets Analytics",
    customerInsight = "Customer Insight",
    salesAnalytics = "Sales Analytics"
}
const salesChartlabels = {
    salesTitle: "Stores - Order & Sales",
    headers: ['Store Name', 'Total Sale', 'Total Order']
}
enum commodityStages {
    commodityStage = "Commodity View",
    dataEnrichementStage = "Data Enrichement",
    dashboardStage = "Dashboard"
}

enum reportBaseType {
    current = "current",
    previous = "previous",
    next = "next"
}
enum reportFilterType {
    Year = "Year",
    Month = "Month",
    Week = "Week"

}
const dateTimeLiterals = {
    TimeCalu: (24 * 60 * 60 * 1000)
}
enum orderConstant {
    orderInvoice = "orderinvoice"
}

export const commodityCategories = [
    "Art and Craft Supplies",
    "Audio and Headphones",
    "Automotive",
    "Baby and Kids",
    "Books and Media",
    "Cameras and Photography",
    "Electronics",
    "Computers and Laptops",
    "Drones and Accessories",
    "Fashion and Apparel",
    "GPS and Navigation Devices",
    "Health and Beauty",
    "Home Appliances",
    "Home and Kitchen",
    "Home Theater Systems",
    "Jewelry and Accessories",
    "Printers and Scanners",
    "Smart Home Devices",
    "Smartphones",
    "Sports and Fitness",
    "Tablets",
    "Televisions",
    "Toys and Games",
    "Travel and Luggage",
    "Video Games and Consoles",
    "Virtual Reality (VR) and Augmented Reality (AR) Devices",
    "Wearable Technology"
]


export const commodityTags = [
    "Accessories",
    "Android",
    "Audio",
    "Camera",
    "Car Electronics",
    "Drones",
    "Gaming",
    "Headphones",
    "Home Appliances",
    "iPhone",
    "Laptop",
    "Networking",
    "Printers",
    "Smartphone",
    "Smart Home",
    "Tablet",
    "TV",
    "Virtual Reality (VR)",
    "Wearables"
]

export const commodityTypesOption = [
        "Air Conditioners",
        "Cameras (Digital Cameras, DSLRs, Mirrorless Cameras)",
        "Coffee Makers",
        "Computer Components (CPUs, GPUs, RAM, Motherboards)",
        "Computer Peripherals (Keyboards, Mice, Monitors)",
        "Desktop Computers",
        "Dishwashers",
        "Drones",
        "External Storage (Hard Drives, USB Flash Drives)",
        "Gaming Consoles",
        "Headphones and Earphones",
        "Irons",
        "Laptops",
        "Mobile Accessories (Cases, Screen Protectors, Chargers)",
        "Networking Devices (Routers, Modems)",
        "Ovens (Microwaves, Conventional Ovens)",
        "Printers",
        "Projectors",
        "Refrigerators",
        "Smart Home Devices (Smart Bulbs, Smart Plugs, Smart Thermostats)",
        "Smartphones",
        "Speakers (Bluetooth Speakers, Home Theater Systems)",
        "Tablets",
        "Televisions",
        "Toasters",
        "Vacuum Cleaners",
        "Virtual Reality (VR) Headsets",
        "Washing Machines",
        "Water Purifiers",
        "Wearable Technology (Smartwatches, Fitness Trackers)"
      
      
]

export const couponRules = [
    "Product released before",
    "Product released after",
    "Number of product left",
    "Product price above",
    "product price below",
    "Customer with in km",
    "Number of products sold below",
    "Number of products sold above",
]

enum chatGroupType {
    private = "private"
}

export const chatConfig = {
    chatGroupType: chatGroupType
}

export enum CouponLevels {
    ItemLevel = "ItemLevel",
    StoreLevel = "StoreLevel",
    CartLevel = "CartLevel"
}

export const CouponLevelsList = [
    CouponLevels.ItemLevel,
    CouponLevels.StoreLevel,
    CouponLevels.CartLevel
]

export const CommodityMandatoryFields = [
    {
        name: "Description",
        type: commodityDataTypes.text
    },
    {
        name: "Brand",
        type: commodityDataTypes.text
    },
    {
        name: "IsPerishable",
        type: commodityDataTypes.text
    },
    {
        name: "Price",
        type: commodityDataTypes.number
    },
    {
        name: "Manufacturer",
        type: commodityDataTypes.text
    }
]

// TODO: Refactor needed
export const UtcOffset = 6
export const TIMEZONE = -5

export const commonImageExtensions = [
    ".apng",
    ".avif",
    ".gif",
    ".jpg",
    ".jpeg",
    ".jfif",
    ".pjpeg",
    ".pjp",
    ".png",
    ".svg",
    ".webp",
]
export const tableConst ={
    EditTable :"editTable",
    SearchTable : "searchTable",

}
export const integrationChannelTemplate = 'https://raw.githubusercontent.com/AICorpGlobal/public-buyconn-release-artifiacts/master/buyconn-templates/seller-integration-componenets.json'
export {
    CommonConfig,
    reportFilterType,
    reportQueryFiterBy,
    UserViewConstants,
    StoreViewConstants,
    CustomizeTemplateDataType,
    ViewModelStatus,
    CataloguePublishStatus,
    commodityDataTypes,
    FileUploadCommodity,
    ReportIssue,
    getReportAganistReportId,
    bulkUploadCommodityFileConfig,
    SellerBillingDocFileConfig,
    days,
    months,
    searchAttributes,
    signUpOTPAdditionalAttrib,
    forgetPasswordOTPAdditionalAttrib,
    getToTalOrderLabel,
    setSalesChartLabel,
    reportStages,
    dashboardStages,
    salesChartlabels,
    reportBaseType,
    dateTimeLiterals,
    orderConstant,
    DeliveryConstant,
    DeliveryModel,
    DeliveryTypes,
    customerDataTypes,
    mediaFileConfig,
    commodityStages
}