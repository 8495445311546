import { IUserDataModel } from "@/Interfaces/DataModel/UserDataModel";
import ApiService from "../Common/ApiService";
import { AuthApi, UserApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import axios from "axios";
import store from "@/store";
import { config } from "@/config/apiConfig";
function CreateAUser(sellerId: string, data: IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.CreateAUser(sellerId)
    return ApiService.SendRequest(url, method, { data })

}
function GetAllUsers(sellerId: string): Promise<IResponseDataModel<IUserDataModel[]>> {
    const { url, method }: { url: string, method: string } = UserApi.GetAllUsers(sellerId)
    return ApiService.SendRequest(url, method)

}
function GetAUser(sellerId: string, userId: string): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.GetAUser(sellerId, userId)
    return ApiService.SendRequest(url, method)

}
function UpdateAUser(sellerId: string, userId: string, data: IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.UpdateAUser(sellerId, userId)
    return ApiService.SendRequest(url, method, { data })

}
function DeleteAUser(sellerId: string, userId: string): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.DeleteAUser(sellerId, userId)
    return ApiService.SendRequest(url, method)

}
function UserCount(sellerId: string): Promise<IResponseDataModel<number>> {
    const { url, method }: { url: string, method: string } = UserApi.UserCount(sellerId)
    return ApiService.SendRequest(url, method)

}

function userPermissionUpdate(sellerId: string, userId: string, data: IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.UserPermissionUpdate(sellerId, userId)
    return ApiService.SendRequest(url, method, { data })
}
async function refreshToken(sellerEmail: string, refreshToken: string) {
    const { url, method }: { url: string, method: string, } = AuthApi.refreshToken

    const apiconfig = {
        method: method,
        maxBodyLength: Infinity,
        url: `${config.BaseUrl}${url}`,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${store.getters['userCred/getAccessToken']}`
        },
        params: { email: sellerEmail }
    };
    const response = await axios.request(apiconfig);
    return response.data
}

export {
    CreateAUser,
    GetAUser,
    GetAllUsers, UpdateAUser,
    DeleteAUser,
    UserCount,
    userPermissionUpdate,
    refreshToken
}