import * as yup from "yup";

import { inputFieldConstants as SellerAttributes } from "@/views/configs/sellerProfileConfig";
import { inputFieldConstants as StoreAttributes } from "@/views/configs/storeProfileConfig";
import { inputFieldConstants as UserAttributes } from "@/views/configs/userProfileConfig";
import { ForgetPasswordFieldAttributes as ForgetPasswordAttributes, SignUpFieldAttributes, LoginFieldAttributes } from '@/views/configs/authPagesConfig'

import { StoreViewConstants, UserViewConstants } from "@/config/dataConfig";
import { computed } from "vue";


// eslint-disable-next-line
const websiteRegex = /^(?:https?:\/\/|s?ftps?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/gm
const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/

enum schemaName {
  login = "LoginSchema",
  forgetPassword = "forgetPasswordSchema",
  signUp = "SignUpSchema",
  seller = "SellerDetailsInputSchema",
  store = "StoreDetailsInputSchema",
  user = "UserDetailsInputSchema",
  coupon = "CouponDetailsInputSchema",
  Loyalty = "LoyaltyDetailsInputSchema"
}

enum Constants {
  OtpLength = 6,
  inviteCodeLength=6,
  PhoneNumberLength = 10,
  pinCodeLength = 6,
  passWordMin = 6,
  passwordMax = 64,
  userNameMin = 3,
  userNameMax = 32,
  addressMin = 3, // TODO: update
  addressMax = 256,
  locationAttributeMin = 3,
  locationAttributeMax = 64,
  geocodeMin = 5,
  geocodeMax = 12,
}

const isLengthValid = (
  key: string | number | undefined,
  requiredLength: number
): boolean => (key ? key.toString().length === requiredLength : false);

const commonSchema = {
  email: yup.string().required().email(),
  password: yup
    .string()
    .required()
    .min(Constants.passWordMin, "Too small")
    .max(Constants.passwordMax),
  phoneNumber: yup
    .number()
    .required()
    .test((input) => isLengthValid(input, Constants.PhoneNumberLength))
    .typeError("Phone Number must be a number"),
  otp: yup
    .number()
    .required()
    .test((input) => isLengthValid(input, Constants.OtpLength))
    .typeError(`OTP must be ${Constants.OtpLength} digit number`),
  address: yup
    .string()
    .required()
    .min(Constants.addressMin)
    .max(Constants.addressMax)
    .nullable(),
  locationAttribute: yup
    .string()
    .required()
    .min(Constants.locationAttributeMin)
    .max(Constants.locationAttributeMax)
    .nullable(),
  name: yup
    .string()
    .required()
    .min(Constants.userNameMin)
    .max(Constants.userNameMax)
    .nullable(),
  pinCode: yup
    .number()
    .required()
    .test((input) => isLengthValid(input, Constants.pinCodeLength))
    .typeError('Pincode must be a number')
    .nullable(),
  inviteCode: yup
    .string()
    .required()
    .test((input) => isLengthValid(input, Constants.OtpLength))
    .typeError('InviteCode must be a number')
};

const schemas = {
  [schemaName.login]: {
    [LoginFieldAttributes.email.componentName]: commonSchema.email,
    [LoginFieldAttributes.password.componentName]: yup.string().required(),
  },

  [schemaName.signUp]: {
    [SignUpFieldAttributes.name.componentName]: commonSchema.name,
    [SignUpFieldAttributes.password.componentName]: commonSchema.password,
    [SignUpFieldAttributes.phoneNumber.componentName]: commonSchema.phoneNumber,
    [SignUpFieldAttributes.email.componentName]: commonSchema.email,
    [SignUpFieldAttributes.otp.componentName]: commonSchema.otp,
     [SignUpFieldAttributes.inviteCode.componentName]: commonSchema.inviteCode,
    [SignUpFieldAttributes.confirmPassword.componentName]: yup
      .string()
      .oneOf([yup.ref(SignUpFieldAttributes.password.componentName)], "Password does not match"),
  },

  [schemaName.forgetPassword]: {
    [ForgetPasswordAttributes.email.componentName]: commonSchema.email,
    [ForgetPasswordAttributes.otp.componentName]: commonSchema.otp,
    [ForgetPasswordAttributes.password.componentName]: commonSchema.password,
  },

  [schemaName.seller]: {
    [SellerAttributes.name.componentName]: commonSchema.name,
    [SellerAttributes.address.componentName]: commonSchema.address,
    [SellerAttributes.city.componentName]: commonSchema.locationAttribute,
    [SellerAttributes.district.componentName]: commonSchema.locationAttribute,
    [SellerAttributes.state.componentName]: commonSchema.locationAttribute,
    [SellerAttributes.country.componentName]: commonSchema.locationAttribute,
    [SellerAttributes.managerName.componentName]: commonSchema.name,
    [SellerAttributes.managerPhoneNumber.componentName]:
      commonSchema.phoneNumber,
    [SellerAttributes.email.componentName]: commonSchema.email,
    [SellerAttributes.phoneNumber.componentName]: commonSchema.phoneNumber,
    [SellerAttributes.pinCode.componentName]: commonSchema.pinCode,
  },

  [schemaName.store]: {
    [StoreAttributes.name.componentName]: commonSchema.name,
    [StoreAttributes.email.componentName]: commonSchema.email,
    [StoreAttributes.website.componentName]: yup.string().matches(websiteRegex, 'Website is invalid').notRequired().nullable(),
    [StoreAttributes.phoneNumber.componentName]: commonSchema.phoneNumber,
    [StoreAttributes.categories.componentName]: yup
      .string()
      .oneOf(StoreViewConstants.StoreCategories)
      .notRequired(),
    [StoreAttributes.managerName.componentName]: commonSchema.name,
    [StoreAttributes.managerPhoneNumber.componentName]:
      commonSchema.phoneNumber,
    [StoreAttributes.address.componentName]: commonSchema.address,
    [StoreAttributes.geoCode.componentName]: yup
      .string()
      .required()
      .min(Constants.geocodeMin)
      .max(Constants.geocodeMax),
    [StoreAttributes.city.componentName]: commonSchema.locationAttribute,
    [StoreAttributes.district.componentName]: commonSchema.locationAttribute,
    [StoreAttributes.pinCode.componentName]: commonSchema.pinCode,
    [StoreAttributes.state.componentName]: commonSchema.locationAttribute,
    [StoreAttributes.country.componentName]: commonSchema.locationAttribute,
    [StoreAttributes.gstNumber.componentName]: yup.string().matches(gstinRegex, 'GSTIN is invalid').nullable(),
  },

  [schemaName.user]: {
    [UserAttributes.name.componentName]: commonSchema.name,
    [UserAttributes.email.componentName]: commonSchema.email,
    [UserAttributes.phoneNumber.componentName]: commonSchema.phoneNumber,
    [UserAttributes.permissions.componentName]: yup
      .string()
      .oneOf(UserViewConstants.Permissions)
      .notRequired(),
    [UserAttributes.address.componentName]: commonSchema.address,
    [UserAttributes.pinCode.componentName]: commonSchema.pinCode,
    [UserAttributes.city.componentName]: commonSchema.locationAttribute,
    [UserAttributes.district.componentName]: commonSchema.locationAttribute,
    [UserAttributes.state.componentName]: commonSchema.locationAttribute,
    [UserAttributes.country.componentName]: commonSchema.locationAttribute,
  },
  [schemaName.coupon]: {
    couponName: commonSchema.name,
    // couponType: commonSchema.name,
    cutOffPrice: yup.number().required().min(1).typeError('CutOff Price must be a number'),
    discountPercentage: yup.number().required().min(1).max(100).typeError('Percentage must be a number'),
    numberOfSlips: yup.number().required().min(1).typeError('Coupon limit must be a number'),
    description: yup.string().required()
  // TODO: add all validation
  }

};

function getSchema(name: schemaName) {
  return  computed(() => yup.object(schemas[name]))
}

export { getSchema, schemaName };
