import {

  ICommodityListViewModel,
  ICommodityTableActiveViewModel,
  IFacetObject,
  IVariant
} from "@/Interfaces/DataModel/CommodityDataModel";
import { BulkImportTemplateUrl, commoditymandatoryfield } from "@/views/configs/commodityConfigs";
import {
  keyAndValueExistsInObject,
  stringIsValid,
  itemExistsInArray,
  checkArrayType,
} from "@/service/Common/utilityService";
import { CommodityMandatoryFields, commodityDataTypes } from "@/config/dataConfig";
import { csvDownloadFile, GetACommodity } from "./apiService/CommodityService";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { config } from "@/config/apiConfig";

function IsCommodityNameValid(name: string): boolean {
  return stringIsValid(name);
}


function CategoryAndTagsIsValid(categories: string[], tags: string[]): boolean {
  return categories.length !== 0 && tags.length !== 0;
}

function CommodityCodeIsValid(code: string) {
  return stringIsValid(code);
}

function ValidateCommodityType(type: string) {
  return stringIsValid(type);
}

function IsAllFieldsHasValues(
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
): boolean {
  return commodityActiveTableViewModel.every((tableRow) =>
    keyAndValueExistsInObject(tableRow)
  );
}

function IsImageAvailable(
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
) {
  return commodityActiveTableViewModel.some(
    (tableRow) =>
      tableRow.type.toString().toLowerCase() === commodityDataTypes.file &&
      tableRow.value.toString().includes("http")
  );
}

function validateMandatoryFields(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  const getName = (item: { name: string }) => (item.name || "").toLowerCase()
  return itemExistsInArray(commodityActiveTableViewModel.map(getName), CommodityMandatoryFields.map(getName))
}

function validateMandatoryFieldType(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  return CommodityMandatoryFields.every(field => {
    const row = commodityActiveTableViewModel.find(item => (item.name || "").toString().toLowerCase() == field.name.toLowerCase())
    return row?.type == field.type
  })
}

function validateTableDataTypes(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  return commodityActiveTableViewModel.every((row: any) => {
    if (row.type == commodityDataTypes.number) {
      return typeof row.value == "number" || !isNaN(row.value)
    } else {
      return typeof row.value == "string"
    }
  })
}

function CommodityValidation(
  commodityListViewModel: ICommodityListViewModel,
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
): { isSuccess: boolean, errorMessage: string } {

  const isNameValid = IsCommodityNameValid(commodityListViewModel.commodityName);

  if (!isNameValid) {
    return { isSuccess: false, errorMessage: "Commodity name missing" }
  }

  const isCategoryAndTagValid = CategoryAndTagsIsValid(commodityListViewModel.categories, commodityListViewModel.commodityTags);

  if (!isCategoryAndTagValid) {
    return { isSuccess: false, errorMessage: "Category or Tag missing" }
  }

  const isCommodityCodeValid = CommodityCodeIsValid(commodityListViewModel.commodityCode);

  if (!isCommodityCodeValid) {
    return { isSuccess: false, errorMessage: "Commodity code missing" }
  }

  const isCommodityTypeValid = ValidateCommodityType(commodityListViewModel.commodityType);

  if (!isCommodityTypeValid) {
    return { isSuccess: false, errorMessage: "Commodity type missing" }
  }

  const isAllFieldHasValues = IsAllFieldsHasValues(commodityActiveTableViewModel);

  if (!isAllFieldHasValues) {
    return { isSuccess: false, errorMessage: "Empty values found" }
  }

  const isAllMandatoryFieldExists = validateMandatoryFields(commodityActiveTableViewModel)

  if (!isAllMandatoryFieldExists) {
    return { isSuccess: false, errorMessage: "Mandatory items missing" }
  }

  const isAllMandatoryFieldTypeIsValid = validateMandatoryFieldType(commodityActiveTableViewModel)

  if (!isAllMandatoryFieldTypeIsValid) {
    return { isSuccess: false, errorMessage: "Invalid Field type found." }
  }

  const isAllDataTableFieldTypeIsValid = validateTableDataTypes(commodityActiveTableViewModel)

  if (!isAllDataTableFieldTypeIsValid) {
    return { isSuccess: false, errorMessage: "Invalid Field type found" }
  }

  const imageAvailable = IsImageAvailable(commodityActiveTableViewModel);

  if (!imageAvailable) {
    return { isSuccess: false, errorMessage: "Image not found" }
  }

  return { isSuccess: true, errorMessage: "" }

}

export async function csvDownload() {
  try {
    const blob = await csvDownloadFile(BulkImportTemplateUrl.url);
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', objUrl);
    link.setAttribute('download', BulkImportTemplateUrl.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objUrl);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
}


export async function parseTheHtmlContent(urls) {
  function getRandomItems(arr, count) {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }

  // Get 5 random URLs
  const selectedUrls = getRandomItems(urls, 5);
  const renderedContentPromises = selectedUrls.map(element => getThumbnail(element));

  try {
    const temp = await Promise.all(renderedContentPromises);
    const sortedContent = temp.map(element => ({
      url: element.url,
      source: element.source,
      name: element.title,
      price: `${element.symbol} ${element.price}`,
      image: element.image,
      symbol: element.symbol,
      id: uuidv4(),
      details: element.details
    }));

    // sortedContent.sort((a, b) => a.price - b.price);
    return sortedContent;

  } catch (error) {
    return []; // Return empty array or handle error appropriately
  }
}

function extractImageAndPriceInformation(textContent, element) {
  const noImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/100px-No_image_available.svg.png";

  if (element.includes("amazon")) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(textContent, 'text/html');

    if (!htmlDoc) {
      return {
        title: element,
        source: "",
        price: "N/A",
        symbol: "",
        url: element,
        image: noImage
      };
    }

    const titleElement = htmlDoc.getElementById("productTitle") as HTMLElement; // Type assertion
    const imageElement = htmlDoc.getElementById("landingImage") as HTMLImageElement; // Type assertion
    const symbolElements = htmlDoc.getElementsByClassName("a-price-symbol");
    const priceElements = htmlDoc.getElementsByClassName("a-price-whole");
    const description = parseFeatureBullets(htmlDoc)
    const title = titleElement ? titleElement.innerText : "N/A";
    const image = imageElement ? imageElement.src : noImage; // Safely access src
    const symbol = symbolElements.length > 0 ? (symbolElements[0] as HTMLElement).innerText : ""; // Type assertion
    const priceValue = priceElements.length > 0 ? `${(priceElements[0] as HTMLElement).innerText.replaceAll(',', '')}00` : "0"; // Type assertion
    let jTable = {};
    jTable = extractProductDetailsFromAmazonTable(htmlDoc, "productDetails_techSpec_section_1");
    if (jTable == null) {
      jTable = extractProductDetailsFromAmazonDiv(htmlDoc, "detailBullets_feature_div")
    }
    if (jTable === null) {
      jTable = {}
    }
    jTable["Description"] = description || title || ""
    jTable['Price'] = priceValue || 0
    return {
      title: title,
      source: "amazon",
      symbol: symbol,
      price: Number(priceValue),
      url: element,
      image: image,
      details: jTable,
    };
  }

  if (element.includes("flipkart")) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(textContent, 'text/html');

    if (!htmlDoc) {
      return {
        title: element,
        source: "",
        price: "N/A",
        symbol: "",
        url: element,
        image: noImage
      };
    }

    const titleElement = htmlDoc.getElementsByClassName('VU-ZEz')[0] as HTMLElement; // Type assertion
    const imageElement = htmlDoc.getElementsByClassName("DByuf4 IZexXJ jLEJ7H")[0] as HTMLImageElement; // Type assertion
    const priceElement = htmlDoc.getElementsByClassName("Nx9bqj CxhGGd")[0] as HTMLElement; // Type assertion
    // const description  = htmlDoc.getElementsByClassName('VU-ZEz')[0] as HTMLElement;
    const title = titleElement ? titleElement.innerText.trim() : "N/A";
    const image = imageElement ? imageElement.src : noImage; // Safely access src
    const symbol = priceElement ? priceElement.innerText[0] : ""; // Assume the first character is the symbol
    const priceValue = priceElement ? priceElement.innerText.substring(1).replaceAll(',', '') : "0";
    const details = extractProductDetailsFromFlipkartTable(htmlDoc, 'productDetails_table_class');
    details["Description"] = titleElement || ""
    details['Price'] = priceValue || 0
    return {
      title: title,
      source: "flipkart",
      symbol: symbol,
      price: Number(priceValue),
      url: element,
      image: image,
      details: details
    };
  }
}


async function getThumbnail(element) {
  const response = await axios.get(`${config.proxyValue}/deal-finder/fetch-product-page?url=${encodeURIComponent(element)}`);
  if (response) {
    const htmlContent = response.data;
    const textContent = htmlContent;
    if (!textContent) {
      return {
        url: element,
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/100px-No_image_available.svg.png",
        title: element,
        price: "N/A",
        symbol: "",
        source: "",
      }
    }
    return extractImageAndPriceInformation(textContent, element)

  }
  return {
    url: element,
    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/100px-No_image_available.svg.png",
    title: element,
    symbol: "",
    price: "N/A",
    source: "",
  }
}

function extractProductDetailsFromAmazonTable(htmlDoc, tableId) {
  const table = htmlDoc.getElementById(tableId);
  const jsonData = {};
  if (table) {
    const rows = table.querySelectorAll('tbody tr');
    rows.forEach(row => {
      const cells = row.querySelectorAll('th, td');
      const key = (cells[0] as HTMLElement).innerText.trim();  // Extract the key (header)
      const value = (cells[1] as HTMLElement).innerText.trim(); // Extract the value
      jsonData[key] = value;
    });
    return jsonData;
  } else {
    return null;
  }
}
function extractProductDetailsFromFlipkartTable(htmlDoc, tableClassName) {
  const table = htmlDoc.getElementsByClassName(tableClassName)[0];
  const jsonData = {};
  if (table) {
    const rows = table.querySelectorAll('tbody tr');
    rows.forEach(row => {
      const cells = row.querySelectorAll('th, td');
      const key = (cells[0] as HTMLElement).innerText.trim();  // Extract the key (header)
      const value = (cells[1] as HTMLElement).innerText.trim(); // Extract the value
      jsonData[key] = value;
    });
    return jsonData;
  } else {
    return null;
  }
}

function extractProductDetailsFromAmazonDiv(htmlDoc, divId: string): Record<string, string> | null {
  const div = htmlDoc.getElementById(divId);
  const jsonData: Record<string, string> = {};

  if (div) {
    const listItems = div.querySelectorAll('li');

    listItems.forEach(item => {
      const keyElement = item.querySelector('.a-text-bold') as HTMLElement;
      const valueElement = item.querySelector('span:not(.a-text-bold)') as HTMLElement;

      const key = keyElement ? keyElement.innerText.trim().replace(':', '') : 'N/A';
      const value = valueElement ? valueElement.innerText.trim() : 'N/A';

      jsonData[key] = value;
    });

    return jsonData;
  } else {
    return null;
  }
}

function parseFeatureBullets(doc) {

  const listItems = doc.querySelectorAll('#feature-bullets .a-unordered-list .a-list-item');
  const features = Array.from(listItems).map((item) => {
    const element = item as HTMLElement;
    return element.textContent?.trim() || '';
  }).join(' | ');

  return features;
}



export function parseVariants(apiResponse: any): IVariant[] {
  return apiResponse.result.map((variant: any) => {
    const subVariants = variant.value.map((subVariant: any) => ({
      variantType: variant.key,
      variantValue: subVariant.key || '',
      data: subVariant.value.map((item: any) => ({
        commodityId: item.commodityId,
        commodityName: item.commodityName
      }))
    }));

    return subVariants;
  }).flat();
};
export function convertToDataModel(data, globalCommodityId: string, sellerId: string,commodityName:string) {
  const model = {
    linkType: data.linkType,
    variantType: data.variantType || "",
    sourceId: globalCommodityId,
    destinationId: data.globalCommodityId,
    destinationType:  "",
    variantValue: data.variantValue?data.variantValue.charAt(0).toUpperCase() + data.variantValue.slice(1):"",
    sellerId,
    valueMetadata:[{
      commodityName:commodityName,
    }]
  }
  return model
}

export function convertToViewModel(rawData,commodityId) {
  const filteredData = rawData.result.hits.filter(hit => {
    const { CommodityId = "unknown" } = hit.document;
    return CommodityId	 !== commodityId; 
  });
  const rearrangedData = filteredData.map(hit => {
    const { GlobalCommodityId = "unknown", CommodityName = "unknown", CommodityType = "unknown", __media__ = "" ,SellerId='',CommodityId=""} = hit.document;

    return {
      globalCommodityId: GlobalCommodityId,
      commodityId:CommodityId	,
      commodityName: CommodityName,
      type: CommodityType,
      media: __media__,
      destinationId: "",
      destinationType: "",
      valueMetadata:[],
      linkValue: "",
      sellerId: SellerId,
      sourceId: "",
      sourceType: "",
      variantType:"",
      variantValue:""

    }
  });
  return rearrangedData
}
export function convertResponseToViewModel(data, commodityName) {
  const model = {
    data:[{commodityName:data.commodityName,commodityId:data.destinationId}],
    variantType:data.variantType,
    variantValue:data.variantValue,

  }
  return model
}

export function compareObjects(obj1: IFacetObject[], obj2: IFacetObject[]): { name: string; value: string | number }[] {
  const result: { name: string; value: string | number }[] = [];

  obj2.forEach((item1) => {
      const matchedItem = obj1.find((item2) => item1.name === item2.name);

      if (matchedItem && item1.name !== 'Description' && item1.name !== 'IsPerishable') {
        result.push({ name: item1.name, value: item1.value });
    }
  });
  return result;

}
export { CommodityValidation, IsCommodityNameValid, CategoryAndTagsIsValid };
