import { commodityDataTypes } from "@/config/dataConfig"


const FieldExcludedKeys = ["CommodityId", "CommodityType", "Description", "GlobalCommodityId", "IsGlobalCommodity", "id"]

function searchViewModelToCommodityViewModel(searchResultItem, sellerId, userId, regionId) {
    return {
        commodityId: searchResultItem.CommodityId,
        commodityName: searchResultItem.CommodityName,
        commodityType: searchResultItem.CommodityType,
        globalCommodityId: searchResultItem.GlobalCommodityId,
        isGlobalCommodity: searchResultItem.IsGlobalCommodity,
        description: searchResultItem.description || "",
        sellerId: sellerId,
        categories: [],
        commodityTags: [],
        commodityCode: searchResultItem.CommodityCode || "",
        fields: setCommodityFields(searchResultItem),
        regionId,
        storeId: "",
        userId
    }
}

function setCommodityFields(searchResultItem) {
    const field = []
    for (const key in searchResultItem) {
        if (!(FieldExcludedKeys.includes(key))) {
            field.push({
                name: key.toLocaleLowerCase(),
                value: searchResultItem[key],
                type: getFieldType(searchResultItem, key),
                facet: true,
                optional: true,
                searchable: true
            })
        }
    }
    return field
}

function getFieldType(searchResultItem, key) {
    if (typeof searchResultItem[key] == "string") {
        return searchResultItem[key].includes("http://{host}") ? commodityDataTypes.file : key.toLocaleLowerCase() == "price" ? commodityDataTypes.number : commodityDataTypes.text
    }
    else {
        return "Text"
    }
}

export { searchViewModelToCommodityViewModel }