import { ICatalogueListViewModel, ICatalogueTableActiveViewModel } from "@/Interfaces/DataModel/CatalogueDataModel"
import { stringIsValid, checkArrayType } from "@/service/Common/utilityService"
import { ViewModelStatus as CatalogueStatus, ViewModelStatus } from "@/config/dataConfig"

function catalogueNameIsValid(name: any): boolean {
    return stringIsValid(name)
}

function storeExists(stores: string[]): boolean {
    return stores.length > 0
}

function expiryDateIsValid(validFrom: string, validTo: string, isSaved: string = ViewModelStatus.New): boolean {
    const currentTime = new Date().getTime()
    const validToTime = new Date(validTo).getTime()
    const validFromTime = new Date(validFrom).getTime()
    return validToTime > currentTime && validToTime > validFromTime && (isSaved == ViewModelStatus.New ? validFromTime > currentTime : true)
}

function isDateString(validFrom: string, validTo: string) {
    const isValidDate = (dateString: string) => !isNaN(Date.parse(dateString))
    return isValidDate(validFrom) && isValidDate(validTo)
}

function tableFieldHasValues(catalogueTableData: ICatalogueTableActiveViewModel[]): boolean {
    return catalogueTableData.every(row =>
        Object.values(row).every(value =>
            value !== null && value !== undefined && !(typeof value === 'string' && value.trim() === '')
        )
    );
}

function tableInputFieldIsInt(catalogueTableData: ICatalogueTableActiveViewModel[], tableHeadings: string[]): boolean {
    return catalogueTableData.every(tableRowData => checkArrayType(tableHeadings.map(tableHeading => tableRowData[tableHeading]), 'int'))
}
function tableInputFieldIsGreaterThanZero(
    catalogueTableData: ICatalogueTableActiveViewModel[], 
    tableHeadings: string
  ): boolean {
    return catalogueTableData.every(tableRowData => {
      const resp = tableRowData[tableHeadings];
      return Number(resp) > 0;
    });
  }
  
function tableInputFieldIsInRange(catalogueTableData: ICatalogueTableActiveViewModel[], tableHeading: string, min?: number, max?: number) {
    return catalogueTableData.every(tableRowData => {
        const value = typeof tableRowData[tableHeading] == "number" ? tableRowData[tableHeading] as number : parseFloat(tableRowData[tableHeading] as string || "0.0") as number
        if (max && min) {
            return value <= max && value >= min
        }
        else if (max) {
            return value <= max
        }
        else if (min) {

            return value >= min
        } else {
            return true
        }
    })
}

function publishStatusIsValid(catalogueListItem: ICatalogueListViewModel, catalogueTableData: ICatalogueTableActiveViewModel[]): { isSuccess: boolean, errorMessage: string } {
    const isCatalogueNameIsValid = catalogueNameIsValid(catalogueListItem.catalogueName)

    if (!isCatalogueNameIsValid) {
        return { isSuccess: false, errorMessage: "Catalogue Name missing" }
    }

    const isExpiryDateIsValid = expiryDateIsValid(catalogueListItem.validFromDate, catalogueListItem.validToDate, catalogueListItem.isSaved)

    if (!isExpiryDateIsValid) {
        return { isSuccess: false, errorMessage: "Invalid time period" }
    }

    const isTableInputDiscountValid = tableInputFieldIsInRange(catalogueTableData, "discount", 0, 100)

    if (!isTableInputDiscountValid) {
        return { isSuccess: false, errorMessage: "Invalid discount percentage entered" }
    }

    const isTableInputFieldIsInt = tableInputFieldIsInt(catalogueTableData, ['price', 'quantity', 'discount'])

    if (!isTableInputFieldIsInt) {
        return { isSuccess: false, errorMessage: "Invalid fields entered" }
    }
    const isTableInputFieldIsGreaterThanZero = tableInputFieldIsGreaterThanZero(catalogueTableData, 'price');
    console.log(isTableInputFieldIsGreaterThanZero,"isTableInputFieldIsGreaterThanZero");
    if (!isTableInputFieldIsGreaterThanZero) {
        return { isSuccess: false, errorMessage: "Price field should be greater than zero" }
    }
    const isTableInputFieldNotEmpty = tableFieldHasValues(catalogueTableData)

    if (!isTableInputFieldNotEmpty) {
        return { isSuccess: false, errorMessage: "Please fill the empty fields" }
    }

    const isStoreExists = storeExists(catalogueListItem.stores)

    if (!isStoreExists) {
        return { isSuccess: false, errorMessage: "Store not set" }
    }

    return { isSuccess: true, errorMessage: "" }
}

function unPublishStatusIsValid(catalogueListItem: ICatalogueListViewModel, catalogueTableData: ICatalogueTableActiveViewModel[]): { isSuccess: boolean, errorMessage: string } {
    const isCatalogueNameIsValid = catalogueNameIsValid(catalogueListItem.catalogueName)

    if (!isCatalogueNameIsValid) {
        return { isSuccess: false, errorMessage: "Catalogue Name missing" }
    }

    const isExpiryDateIsValid = isDateString(catalogueListItem.validFromDate, catalogueListItem.validToDate)

    if (!isExpiryDateIsValid) {
        return { isSuccess: false, errorMessage: "Invalid time period" }
    }

    const isStoreExists = catalogueListItem.isSaved === CatalogueStatus.Saved ? storeExists(catalogueListItem.stores) : true

    if (!isStoreExists) {
        return { isSuccess: false, errorMessage: "Store not set" }
    }

    const isTableInputFieldIsInt = tableInputFieldIsInt(catalogueTableData, ['price', 'quantity', 'discount'])

    if (!isTableInputFieldIsInt) {
        return { isSuccess: false, errorMessage: "Invalid fields entered" }
    }


    return { isSuccess: true, errorMessage: "" }

}

function draftStatusIsValid(catalogueListItem: ICatalogueListViewModel, catalogueTableData: ICatalogueTableActiveViewModel[]): { isSuccess: boolean, errorMessage: string } {
    const isCatalogueNameIsValid = catalogueNameIsValid(catalogueListItem.catalogueName)

    if (!isCatalogueNameIsValid) {
        return { isSuccess: false, errorMessage: "Catalogue Name missing" }
    }

    const isExpiryDateIsValid = isDateString(catalogueListItem.validFromDate, catalogueListItem.validToDate)

    if (!isExpiryDateIsValid) {
        return { isSuccess: false, errorMessage: "Invalid time period" }
    }
    const isTableInputFieldIsInt = tableInputFieldIsInt(catalogueTableData, ['price', 'quantity', 'discount'])

    if (!isTableInputFieldIsInt) {
        return { isSuccess: false, errorMessage: "Invalid fields entered" }
    }

    const isTableInputDiscountValid = tableInputFieldIsInRange(catalogueTableData, "discount", 0, 100)

    if (!isTableInputDiscountValid) {
        return { isSuccess: false, errorMessage: "Invalid discount percentage entered" }
    }

    return { isSuccess: true, errorMessage: "" }


}

export { publishStatusIsValid, unPublishStatusIsValid, draftStatusIsValid, expiryDateIsValid, catalogueNameIsValid, storeExists, tableInputFieldIsInt }