import { IUserCredentialDataModel } from "@/Interfaces/DataModel/UserCredentialsDataModel";
import { actionsConstant, mutationsConstant } from "./constants/userCredentials";
import { decryptToken } from "@/service/tokenService"


function defaultState(): IUserCredentialDataModel {
  return {
    userId: null,
    sellerId: null,
    accessToken: null,
    refreshToken: null,
    email: null,
  }
};

export default {

  namespaced: true,

  state: defaultState(),

  mutations: {

    [mutationsConstant.setUserId](state: IUserCredentialDataModel, payload: string) {
      state.userId = payload;
    },

    [mutationsConstant.setSellerId](state: IUserCredentialDataModel, payload: string) {
      state.sellerId = payload;
    },
    [mutationsConstant.setAuth](state: IUserCredentialDataModel, payload: any) {
      const result = decryptToken(payload.accessToken)
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.userId = result.userId;
      state.sellerId = result.sellerId;
      state.email = result.email
    },

    resetState(state: any) {
      Object.assign(state, defaultState())
    }
  },

  actions: {

    [actionsConstant.setUserId](ctx: any, payload: string) {
      ctx.commit(mutationsConstant.setUserId, payload);
    },

    [actionsConstant.setSellerId](ctx: any, payload: string) {
      ctx.commit(mutationsConstant.setSellerId, payload);
    },

    [actionsConstant.setAuth](ctx: any, payload: any) {
      ctx.commit(mutationsConstant.setAuth, payload);
     
    },

    resetState(ctx: any) {
      console.log('reset called', defaultState())

      ctx.commit('resetState')
    },
 

  },
  getters: {

    getUserId: (state: IUserCredentialDataModel) => state.userId,
    getSellerId: (state: IUserCredentialDataModel) => state.sellerId,
    getAccessToken: (state: IUserCredentialDataModel) => state.accessToken,
    getRefreshToken: (state: IUserCredentialDataModel) => state.refreshToken,
    getEmail: (state: IUserCredentialDataModel) => state.email,
  },
}
