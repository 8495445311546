const inputFieldConstants = {
  name: {
    id: "storeName",
    label: "Store Name",
    placeHolder: "Store Name",
    componentName: "Store Name",
  },

  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  website: {
    id: "websiteUrl",
    label: "Website",
    placeHolder: "Store Website",
    componentName: "Website",
  },

  gstNumber: {
    id: "gstnumber",
    label: "GSTIN number",
    placeHolder: "Store GSTIN number",
    componentName: "GSTIN Number",
  },

  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },

  categories: {
    id: "categories",
    label: "Categories",
    placeHolder: "",
    componentName: "Categories",
  },
  delivery: {
    id: "delivery",
    label: "Delivery Type",
    placeHolder: "",
    componentName: "Delivery",
  },
  workingHour: {
    id: "workingHour",
    label: "Working Hour",
    placeHolder: "",
    componentName: "Working Hour",
  },
  description: {
    id: "description",
    label: "Description",
    placeHolder: "",
    componentName: "Description",
  },
  banner: {
    id: "banner",
    label: "Banner",
    placeHolder: "",
    componentName: "Banner",
  },
  managerName: {
    id: "managerName",
    label: "Manager Name",
    placeHolder: "Store Manager Name",
    componentName: "Manager Name",
  },

  managerPhoneNumber: {
    id: "managerPhoneNumber",
    label: "Phone Number",
    placeHolder: "Manager Phone Number",
    componentName: "Manager Phone Number",
  },
  deliveryFee:{
    id: "deliveryFee",
    label: "Delivery Fee",
    placeHolder: "Enter Delivery Fee",
    componentName: "deliveryFee",
  },
  freeDeliveryPerKm:{
    id: "freeDeliveryPerKm",
    label: "Delivery Fee Per Km",
    placeHolder: "Enter Delivery Fee Per Km",
    componentName: "freeDeliveryPerKm",
  },
  address: {
    id: "address",
    label: "Address",
    placeHolder: "store Address",
    componentName: "Address",
  },

  geoCode: {
    id: "geocode",
    label: "GeoCode",
    placeHolder: "Store GeoCode",
    componentName: "Geocode",
  },

  city: {
    id: "city",
    label: "City",
    placeHolder: "City",
    componentName: "City",
  },

  district: {
    id: "district",
    label: "District",
    placeHolder: "District",
    componentName: "District",
  },

  pinCode: {
    id: "pincode",
    label: "Pincode",
    placeHolder: "Pincode",
    componentName: "Pincode",
  },

  state: {
    id: "state",
    label: "State",
    placeHolder: "State",
    componentName: "State",
  },

  country: {
    id: "country",
    label: "Country",
    placeHolder: "Country",
    componentName: "Country",
  }

};


export function createNewStoreCssStyle(isDarkMode: Boolean) {
  return `
  <style>
    .swal2-input {
      font-size: 12px; 
      padding: 5px; 
      background-color: ${isDarkMode ? "#111c44" : "#fff"}; 
      color: ${isDarkMode ? "#fff" : "#000"};
    }
.swal2-styled.swal2-cancel {
      font-size: 15px; 
      padding: 5px 10px;
      border-radius: 0.5rem;
    }
            .swal2-styled.swal2-confirm {
      font-size: 15px; 
      padding: 5px 10px;
    }
  </style>
  <h2 style="font-size: 1.0rem; margin: 0;">Enter New Store Name</h2>
`
}

export { inputFieldConstants };

export function defaultWorkingHour() {
  const model = [
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
    [{ start: "10:00", end: "07:00", isHoliday: false }],
  ]
  return model
}