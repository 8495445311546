import { IStoreActiveViewModel } from "@/Interfaces/DataModel/StoreDataModel";
import { IUserActiveViewModel } from "@/Interfaces/DataModel/UserDataModel";
import { ILoginViewModel } from "@/Interfaces/DataModel/LoginDataModel";
import { IItemListActiveViewModel } from "@/Interfaces/DataModel/ItemListViewModel";
import { ISellerActiveViewModel } from "@/Interfaces/DataModel/SellerDataModel";
import {
  ViewModelStatus,
  CataloguePublishStatus,
  commodityDataTypes,
  getToTalOrderLabel,
  CouponLevels,
} from "@/config/dataConfig";
import {
  ICatalogueListViewModel,
  ICatalogueTableActiveDataModel,
  ICatalogueTableActiveViewModel,
} from "@/Interfaces/DataModel/CatalogueDataModel";
import {
  ICommodityListViewModel,
  ICommodityTableActiveViewModel,
} from "@/Interfaces/DataModel/CommodityDataModel";
import { CommodityTypes, commoditymandatoryfield } from "@/views/configs/commodityConfigs";
import {
  ICouponDataModel,
  ICouponActiveViewModel,
} from "@/Interfaces/DataModel/CouponDataModel";
import { IIntegrationActiveViewModel } from "@/Interfaces/DataModel/IntegrationDataModel";

import { IStoreDomianViewModel, IStoreTemplateViewModel } from "@/Interfaces/DataModel/StoreTemplateDataModel";

import { ICollectionListViewModel } from "@/Interfaces/DataModel/productDataModel";
import { IReportDataModel } from "@/Interfaces/DataModel/ReportDataModel";
import { modifyDate, modifyValidFromDate, modifyValidToDate } from "../Common/utilityService";
import { ICustomerProfileListViewModel, ICustomerTableActiveViewModel, IDynamicFields } from "@/Interfaces/DataModel/CustomerDataModel";
import { ILoyaltyActiveViewModel } from "@/Interfaces/DataModel/LoyaltyDataModel";


function ToEmptyInitSellerActiveViewModel(): ISellerActiveViewModel {
  const emptyModel: ISellerActiveViewModel = {
    address: "",
    city: "",
    country: "",
    district: "",
    email: "",
    isLocked: "",
    files: [],
    logo: "",
    orgType: "",
    phoneNumber: "",
    pinCode: "",
    sellerName: "",
    state: "",
    status: "",
    website: "",
    managerName: "",
    pointOfContact: "",
    sellerId: "",
    storeId: "",
    userId: "",
    regionId: "",
  };

  return emptyModel;
}

function ToEmptyInitStoreActiveViewModel(
  storeName: string = "",
  sellerId: string = "",
  regionId: string = ""
): IStoreActiveViewModel {
  const emptyModel: IStoreActiveViewModel = {
    storeImage: "",
    category: [],
    city: "",
    contactNumber: "",
    country: "",
    district: "",
    email: "",
    geoCode: "",
    pinCode: "",
    primaryCategory: "",
    secondaryPhoneNumber: "",
    state: "",
    storeAddress: "",
    storeManagerName: "",
    storeName,
    tags: [],
    websiteUrl: "",
    sellerId,
    regionId,
    userId: "",
    storeId: "",
    deliveryType: [],
    deliveryViewModel: "PickUp",
    gstNumber: "",
    status: ViewModelStatus.New,
    introduction: "",
    workingHours: {},
    banner: "",
    sections:
      [{
        count: "",
        name: "",
        position: "",
        sectionData: {
          dataType: "Banner",
          resultData: [
            {
              key: "",
              value: ""
            }
          ]
        },
        sectionType: ""
      }],
      attributes:{},
      deliveryFee:"",
      freeDeliveryPerKm:""

  };

  return emptyModel;
}
export function initSection() {
  const emptyModel = {

    count: "",
    name: "",
    position: "",
    sectionData: {
      dataType: "",
      resultData: [
        {
          key: "",
          value: ""
        }
      ]
    },
    sectionType: ""

  }
  return emptyModel
}

function ToEmptyInitUserActiveViewModel(
  name: string = "",
  sellerId: string = "",
  regionId: string = ""
): IUserActiveViewModel {
  const emptyModel: IUserActiveViewModel = {
    name,
    sellerId,
    regionId,
    IsSaved: ViewModelStatus.New,
    permission: [],
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    district: "",
    pinCode: "",
    country: "",
    state: "",
    userId: "",
    status: "",
    IsLocked: false,
    storeId: "",
    cartId: "",
    secondaryPhone: "",
  };
  return emptyModel;
}
function ToEmptyInitLoginActiveViewModel() {
  const emptyModel: ILoginViewModel = {
    userId: "",
    name: "",
    password: "",
    phoneNumber: "",
    email: "",
    otp: "",
    confirmPassword: "",
  };
  return emptyModel;
}
function ToEmptyItemListActiveViewModel() {
  const emptyModel: IItemListActiveViewModel = {
    status: "",
    id: "",
    name: "",
  };
  return emptyModel;
}
function ToEmptyCatalogueTableActiveViewModel() {
  const emptyModel: ICatalogueTableActiveDataModel = {
    regionId: "",
    sellerId: "",
    storeId: "",
    userId: "",
    catalogueId: "",
    catalogueName: "",
    catalougeVersion: "",
    remark: "",
    status: "",
    publishStatus: false,
    stores: [],
    tags: [],
    validFromDate: "",
    validToDate: "",
    isSaved: "",
    commodities: {
      additionalProp1: [
        {
          name: "string",
          value: "string",
          type: "string",
          facet: true,
          optional: true,
          searchable: true,
        },
      ],
    },
  };
  return emptyModel;
}

function ToEmptyCatalogueListViewModel(
  sellerId: string,
  regionId: string,
  userId: string
): ICatalogueListViewModel {
  const emptyModel = {
    regionId: regionId,
    catalogueId: "",
    sellerId: sellerId,
    storeId: "",
    userId: userId,
    catalogueName: "New",
    catalougeVersion: "0",
    remark: "",
    status: CataloguePublishStatus.draft,
    publishStatus: false,
    stores: [],
    tags: [],
    isSaved: ViewModelStatus.New,
    validFromDate: modifyValidFromDate((new Date()).toISOString()),
    validToDate: modifyValidToDate((new Date()).toISOString()),
  };
  return emptyModel;
}

function ToEmptyCommodityListViewModel(
  sellerId: string,
  regionId: string,
  userId: string
): ICommodityListViewModel {
  const emptyModel = {
    categories: [],
    commodityId: "",
    commodityName: "New",
    commodityTags: [],
    commodityType: CommodityTypes.Electronics,
    commodityCode: "",
    globalCommodityId: "",
    regionId,
    sellerId,
    storeId: "",
    userId,
    isSaved: ViewModelStatus.New,
    images: [],
    deliveryType: [],
    deliveryViewModel: "PickUp"
  };

  return emptyModel;
}

function ToEmptyCommodityTableViewModel(): ICommodityTableActiveViewModel[] {
  const keyIs = (item: string, key: string): boolean =>
    item.toLocaleLowerCase() === key;
  const getValue = (name: string): string => (keyIs(name, "price") ? "0" : "");
  const getType = (name: string): string =>
    keyIs(name, "price") ? commodityDataTypes.number : commodityDataTypes.text;
  const emptyModel = commoditymandatoryfield.map((name) => ({
    name: name,
    value: getValue(name),
    type: getType(name),
    searchable: true,
    facet: true,
    optional: false,
  }));

  return emptyModel;
}


function ToEmptyCouponActiveViewModel(
  couponName = "",
  sellerId = "",
  regionId = "",
  userId: string
): ICouponActiveViewModel {
  return {

    sellerId,
    regionId,
    userId,
    storeId: "",

    couponBookletId: "",
    couponBookletType: "Public",
    couponBookletLevel: CouponLevels.StoreLevel,
    couponCode: "",
    couponName,
    numberOfSlips: 0,
    cutOffPrice: 0,
    discountPercentage: 0,
    description: "",
    customerCategory: [],

    rules: [],
    productDistribution: [],
    storesDistribution: [],

    validFrom: modifyValidFromDate((new Date()).toISOString()),
    validTo: modifyValidToDate((new Date()).toISOString()),

    status: "",

    isSaved: ViewModelStatus.New,
    publishStatus: false,

  }
}

function ToEmptyLoyaltyActiveViewModel(coinName,
  sellerId = "",
  regionId = "",
  userId: string
): ILoyaltyActiveViewModel {
  return {

    sellerId,
    regionId,
    userId,
    storeId: "",
    validFrom: modifyValidFromDate((new Date()).toISOString()),
    validTo: modifyValidToDate((new Date()).toISOString()),
    stores: [],
    loyalityType: "",
    minimumCutOff: 0,
    coinEquivalentRate: 0,
    loyalityDefinitionId: "",
    issueVersion: "",
    status: "",
    currencyCode: "",
    isSaved: ViewModelStatus.New,
    // publishStatus: false,
    coinName,
    description: "",
    coins: 0,

  }
}
function ToEmptyIntegrationActiveViewModel(
  sellerId: string,
  regionId: string,
  userId: string,
) {
  const emptyModel = {
    source: "",
    componentId: "",
    componentName: "",
    integrationName: "",
    parameters: {},
    sellerId,
    regionId,
    userId,
    IsSaved: "NEW",
    isDefault: true
  };
  return emptyModel;
}
function ToEmptyIntegrationSelectionViewModel(
  sellerId: string,
  regionId: string,
  userId: string,
) {
  const emptyModel = {
    source: "",
    sellerId,
    regionId,
    userId,
    IsSaved: "NEW",
    type: "",
    integrationName: "",
    icon: "",
    tag: "",
    parameters: {},
    componentId: "",
    componentName: ""
  };
  return emptyModel;
}
function ToEmptyStoreTemplateActiveViewModel(
  sellerId: string,
  regionId: string,
  userId: string,
) {
  const emptyModel = {
    backgroundType: "",
    backgroundColor: "",
    backgroundImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0bquih_e5sUIShI_zl0LuSuLZ3djWee0wu9FS3q7KyRu1UVXt-SyGLNgi-M9gLHEA4ao&usqp=CAU",
    backgroundImageFileUrl: "",
    buttonColor: "",
    storeName: "",
    storeAddress: "",
    bodyBackgroundColor: "",
    buttonText: "",
    buttonTextColor: "",
    position: "Center",
    logo: "https://img.freepik.com/premium-vector/store-retail-logo-template_59362-82.jpg",
    logImageFileUrl: "",
    logoType: "",
    bannerText: "",
    bannerImage: "https://pocketapp.in/img/blog-img/electronics1.jpg",
    bannerImageFileUrl: "",
    bannerTextColor: "",
    layoutPosition: "",
    sellerId,
    regionId,
    facebook: "",
    twitter: "",
    instagram: "",
    headerColor: "",
    userId,
    IsSaved: "NEW",
    
  };
  return emptyModel;
}
function ToEmptyStoreCustomizedTemplateDataModel()
  : IStoreTemplateViewModel {
  const emptyModel: IStoreTemplateViewModel = {
    section: [
      {
        position: "0",
        sectionType: "storeNameBanner",
        name: "storeNameBanner",
        sectionData: {
          dataType: "null"
        },
        attributes: {
          logoShape: "",
          contentAlignment: "",
          bgColor: "",
          backgroundImage: "",
          layoutPosition: ""
        }
      },
      {
        position: "1",
        sectionType: "bodySection",
        name: "bodySection",
        sectionData: {
          dataType: "null"
        },
        attributes: {
          contentAlignment: "",
          bgColor: "#",
          buttonColor: "",
          buttonText: ""
        }
      },
      {

        position: "2",
        sectionType: "footerSection",
        name: "footerSection",
        sectionData: {
          dataType: "data",
          data: {
            contactUs: {
              name: "",
              contactNumber: "",
              comment: "",
              socialLink: [
                {
                  instagram: "url"
                },
                {
                  facebook: "url"
                }
              ]
            },
            aboutAs: ""
          }
        },
        attributes: {}

      },
    ]
  }
  return emptyModel

}
function ToEmptyStoreDomain(storeName: string = "",
  sellerId: string = "",
  regionId: string = ""
): IStoreActiveViewModel {
  const emptyModel: IStoreActiveViewModel = {
    storeImage: "",
    category: [],
    city: "",
    contactNumber: "",
    country: "",
    district: "",
    email: "",
    geoCode: "",
    pinCode: "000000",
    primaryCategory: "",
    secondaryPhoneNumber: "",
    state: "",
    storeAddress: "",
    storeManagerName: "",
    storeName,
    tags: [],
    websiteUrl: "",
    sellerId,
    regionId,
    userId: "",
    storeId: "",
    gstNumber: "",
    deliveryType: [],
    deliveryViewModel: "PickUp",
    status: ViewModelStatus.New,
    introduction: "",
    workingHours: {},
    banner: "",
    sections:
      [{
        count: "",
        name: "",
        position: "",
        sectionData: {
          dataType: "Banner",
          resultData: [
            {
              key: "",
              value: ""
            }
          ]
        },
        sectionType: ""
      }],
      attributes:{},
      freeDeliveryPerKm:"",
      deliveryFee:""

  };

  return emptyModel;
}

function ToEmptyReportViewModel(): IReportDataModel {
  const emptyModel: IReportDataModel = {
    sellerId: '',
    storeId: '',
    base: '',
    endDate: "",
    startDate: "",
    fields: [{ name: getToTalOrderLabel.categorizedByStatusNew, value: 0 }, { name: getToTalOrderLabel.categorizedByStatusAccepted, value: 0 }, { name: getToTalOrderLabel.categorizedByStatusIntransist, value: 0 }],
    orderCount: 0,
    salesValue: 0

  }
  return emptyModel
}
function ToEmptyCustomerProfileListViewModel(
  sellerId: string,
  regionId: string,
  userId: string
): ICustomerProfileListViewModel {
  const emptyModel = {
    customerId: "",
    sellerId,
    storeId: "",
    userId,
    regionId,
    name: "New",
    email: "",
    phoneNumber: "",
    accountStatus: "UnVerified",
    saved: ViewModelStatus.New,
  };

  return emptyModel;
}
function ToEmptyCustomerProfileTableViewModel(): IDynamicFields[] {
  const Model = {
    name: 'WhatsApp',
    value: '',
    type: 'Text',
    searchable: false,
    facet: false,
    optional: false,
  };
  const emptyModel = []
  emptyModel.push(Model)
  return emptyModel;
}

function ToEmptyDynamicField(): IDynamicFields {
  const model = {

    name: "WhatsApp",
    value: false,
    type: "Text",
    searchable: true,
    facet: true,
    optional: false,

  }
  return model
}

function ToEmptyInitWorkingHour() {
  const model = {
    "Sunday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    },
    "Monday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",

    },
    "Tuesday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    },
    "Wednesday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    },
    "Thursday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    },
    "Friday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    },
    "Saturday": {
      "Open": "0000-00-00T09:00:00.000Z",
      "Close": "0000-00-00T17:30:00.000Z",
    }
  }
  return model
}


export {
  ToEmptyInitStoreActiveViewModel,
  ToEmptyInitUserActiveViewModel,
  ToEmptyInitLoginActiveViewModel,
  ToEmptyItemListActiveViewModel,
  ToEmptyInitSellerActiveViewModel,
  ToEmptyCatalogueTableActiveViewModel,
  ToEmptyCatalogueListViewModel,
  ToEmptyCommodityTableViewModel,
  ToEmptyCommodityListViewModel,
  ToEmptyCouponActiveViewModel,
  ToEmptyIntegrationActiveViewModel,
  ToEmptyStoreTemplateActiveViewModel,
  ToEmptyStoreDomain,
  ToEmptyStoreCustomizedTemplateDataModel,
  ToEmptyReportViewModel,
  ToEmptyCustomerProfileListViewModel,
  ToEmptyCustomerProfileTableViewModel,
  ToEmptyDynamicField,
  ToEmptyIntegrationSelectionViewModel,
  ToEmptyLoyaltyActiveViewModel,
  ToEmptyInitWorkingHour
};
